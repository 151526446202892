.experiences {
	width: 70vw;
	margin: auto;
}
.heading {
	width: fit-content;
	margin: auto;
	color: #8993b1;
	line-height: 40px;
}
.borderBottom {
	border-bottom: 3px solid #0282bd;
	width: 200px;
	margin: 10px auto;
	border-radius: 10px;
}
.container {
	width: 75%;
	margin: 20px auto;
}
.position {
	font-size: 1.4em;
	font-weight: 500;
	line-height: 1.3;
}
.position a {
	text-decoration: none;
	color: #00a0a0;
}
.date {
	margin: 20px;
}
.responsibilites {
	width: 90%;
	margin: auto;
}
.responsibilites ul {
	text-align: left;
}
.responsibilites ul li {
	margin: 10px;
	transition: 0.3s;
}
@media screen and (max-width: 1100px) {
	.experiences {
		width: 85vw;
	}
	.container {
		width: 70%;
	}
}
@media screen and (max-width: 900px) {
	.experiences {
		width: 95vw;
	}
	.container {
		width: 80%;
	}
}
@media screen and (max-width: 600px) {
	.container {
		width: 90%;
	}
}
