.container {
	display: flex;
	/* justify-content: center; */
}
.icons {
	width: 80px;
	position: absolute;
	z-index: 10;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0;
	margin-top: 50vh;
	right: 0;
}
.iconsRihgt {
	right: 0;
}
.icons li {
	cursor: pointer;
	list-style: none;
	transition: 0.3s;
}
a {
	display: inline-block;
	text-decoration: none;
	text-decoration-skip-ink: auto;
	color: inherit;
	position: relative;
	transition: var(--transition);
}
.icons li a {
	padding: 10px;
}
.icons li a svg {
	width: 20px;
	height: 20px;
	/* color: #8892b0; */
}

.rightEmail {
	/* background: coral; */
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.rightEmail a:first-child {
	top: -80px;
	transition: 0.3s;
}
.rightEmail a:first-child:hover {
	color: #00a0a0;
	top: -85px;
}
li:hover {
	transform: translateY(-5px);
}
li:hover {
	color: #00a0a0;
}

@media only screen and (max-width: 750px) {
	.container {
		position: relative;
		justify-content: center;
	}
	.icons {
		width: fit-content;
		height: fit-content;
		flex-direction: row;
		display: none;
	}
	.rightEmail,
	.line {
		display: none;
	}
}
